"use strict";
'kiwi public';
/** @module */

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.connectionInfoFromConfig = connectionInfoFromConfig;
exports.dateIso = dateIso;
exports.dedotObject = dedotObject;
exports.extractBuffers = extractBuffers;
exports.extractURL = extractURL;
exports.matchUri = matchUri;
exports.mentionsNick = mentionsNick;
exports.networkErrorMessage = networkErrorMessage;
exports.parseIntZero = parseIntZero;
exports.parseIrcUri = parseIrcUri;
exports.parsePresetServer = parsePresetServer;
exports.queryStringVal = queryStringVal;
exports.replaceObjectProps = replaceObjectProps;
exports.stripStyles = stripStyles;

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _isNan = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/number/is-nan"));

var _lodash = _interopRequireDefault(require("lodash"));

var _strftime = _interopRequireDefault(require("strftime"));

var _TextFormatting = require("./TextFormatting");

var strftimeUTC = _strftime.default.timezone('+0');
/**
 * Extract an array of buffers from a string, parsing multiple buffer names and channel keys
 * "#chan,#chan2" => 2 channels without a key
 * "#chan,#chan2 key" => 2 channels, the first having a key
 * "#chan,#chan2 key1,key2" => 2 channels, both having a key
 * "#chan,#chan2 ,key2" => 2 channels, the second having a key
 * @param {string} str List of buffer names and channel keys
 */


function extractBuffers(str) {
  var spaceIdx = str.indexOf(' ');
  if (spaceIdx === -1) spaceIdx = str.length;
  var bufferNames = str.substr(0, spaceIdx).split(',');
  var keys = str.substr(spaceIdx + 1).split(',');
  var buffers = [];
  bufferNames.forEach(function (bufferName, idx) {
    // return if bufferName is empty
    if (!(0, _trim.default)(bufferName).call(bufferName)) {
      return;
    }

    buffers.push({
      name: bufferName,
      key: keys[idx] || ''
    });
  });
  return buffers;
}

function extractURL(str) {
  var matches = str.match(_TextFormatting.urlRegex);
  return matches ? matches[0] : '';
}

function stripStyles(str) {
  return str.replace(/(\x03[0-9]{0,2})?([\x02\x16\x1d\x1f]+)?/g, '');
}
/**
 * Does a string mention a nickname?
 * @param {string} input The string to search within
 * @param {string} nick The nick to search for
 */


function mentionsNick(input, nick) {
  var _context;

  if (input.toLowerCase().indexOf(nick.toLowerCase()) === -1) {
    return false;
  }

  var punc = '\\s,.!:;+()\\[\\]?¿\\/<>@-';

  var escapedNick = _lodash.default.escapeRegExp(nick);

  var stylesStrippedInput = stripStyles(input);
  var r = new RegExp((0, _concat.default)(_context = "(^|[".concat(punc, "])").concat(escapedNick, "([")).call(_context, punc, "]|$)"), 'i');
  return r.test(stylesStrippedInput);
}
/**
 * Get a query string value from the current URL
 * @param {string} _name The query string variable name
 * @param {string} _url The full URL to extract the variable from
 */


function queryStringVal(_name, _url) {
  var url = _url || window.location.href;

  var name = _lodash.default.escapeRegExp(_name);

  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
/**
 * Convert a known error code to a human readable message
 * @param {string} err The error message from the network connection
 */


function networkErrorMessage(err) {
  var errs = {
    err_unknown_host: 'Unknown domain name or host',
    err_forbidden: 'Forbidden to connect',
    err_timeout: 'Took too long to connect',
    err_refused: 'The server refused the connection',
    err_tls: 'Could not connect securely',
    err_proxy: 'The Kiwi IRC server had an error'
  };
  return errs[err] || 'Unknown error';
}
/**
 * Take a users connection object (usually from startupOptions) and normalise a connection
 * settings object. Parses websocket/direct/kiwiServer/etc options and creates a single
 * object that Kiwi can consistently read from.
 * @param {Object} config User provided connection config object
 */


function connectionInfoFromConfig(config) {
  var connection = {
    tls: false,
    port: 0,
    hostname: '',
    direct: false,
    direct_path: ''
  };
  var wsUri = config.websocket ? matchUri(config.websocket) : null;

  if (wsUri) {
    connection.direct = true;
    connection.tls = ['wss', 'https', 'ircs'].indexOf(wsUri.protocol) > -1;
    connection.port = wsUri.port;
    connection.hostname = wsUri.hostname;
    connection.direct_path = wsUri.path;

    if (wsUri.search) {
      connection.direct_path += '?' + wsUri.search;
    }
  } else {
    connection.tls = config.tls;
    connection.port = config.port;
    connection.hostname = config.server;
    connection.direct = !!config.direct;
    connection.direct_path = config.direct_path || '';
  }

  return connection;
}

function matchUri(uri) {
  var reg = /(?:([a-z]+):\/\/)?([a-z.0-9-]+)(?::(?:(\+)?([0-9]+)))?(?:\/([^?]*))?(?:\?(.*))?/;
  /*
  0: "ws://hostname.com:6676/erferf?foo=val"
  1: "ws"
  2: "hostname.com"
  3: undefined
  4: "6676"
  5: "erferf"
  6: "foo=var"
  */

  var m = uri.match(reg);

  if (!m) {
    return null;
  }

  return {
    protocol: (m[1] || '').toLowerCase(),
    hostname: m[2] || '',
    port: parseIntZero(m[4] || ''),
    path: '/' + (m[5] || ''),
    search: m[6] || ''
  };
}
/**
 * Parse a connection string into an object
 * E.g. [ircs?://]irc.network.net:[+]6667/channel?nick=mynick;
 * Multiple connections may be given, separated by ;
 * @param {string} str The connection string URI
 */


function parseIrcUri(str) {
  var reg = /(?:(ircs?):\/\/)?([a-z.0-9-]+)(?::(?:(\+)?([0-9]+)))?(?:\/([^?]*))?(?:\?(.*))?/;
  var connections = [];
  str.split(';').forEach(function (connectionString) {
    var _context2;

    if (!connectionString) {
      return;
    }

    var m = connectionString.match(reg);

    if (!m) {
      return;
    }

    var tls = m[1] === 'ircs' || !!m[3];
    var params = Object.create(null);
    (m[6] || '').split('&').forEach(function (p) {
      var parts = p.split('=');

      if (parts.length === 2) {
        params[parts[0].toLowerCase()] = parts[1];
      }
    });
    var channels = m[5] || params.channel || '';
    channels = (0, _map.default)(_context2 = (0, _lodash.default)(channels.split(',')).compact()).call(_context2, function (_channelName) {
      var hasPrefix = _channelName[0] === '#' || _channelName[0] === '&';
      var channelName = hasPrefix ? _channelName : '#' + _channelName;
      return channelName;
    });
    connections.push({
      tls: tls,
      server: m[2],
      port: parseInt(m[4] || (tls ? 6697 : 6667), 10),
      channels: channels,
      nick: params.nick || '',
      encoding: params.encoding || 'utf8',
      params: params
    });
  });
  return connections;
}
/**
 * Parse preset server string to an object
 * format: freenode|irc.freenode.net:+6697
 * @param {string} input Preset server string
 */


function parsePresetServer(input) {
  var ret = {
    name: '',
    server: '',
    port: 6667,
    tls: false
  };

  ret.toUri = function () {
    var _context3, _context4;

    return (0, _concat.default)(_context3 = (0, _concat.default)(_context4 = "".concat(ret.server, ":")).call(_context4, ret.tls ? '+' : '')).call(_context3, ret.port);
  };

  var val = input;
  var pipePos = val.indexOf('|');

  if (pipePos > -1) {
    ret.name = val.substr(0, pipePos);
    val = val.substr(pipePos + 1);
  }

  var colonPos = val.indexOf(':');

  if (colonPos === -1) {
    ret.server = val;
    val = '';
  } else {
    ret.server = val.substr(0, colonPos);
    val = val.substr(colonPos + 1);
  }

  if (val[0] === '+') {
    ret.tls = true;
    val = val.substr(1);
  }

  if (val.length > 0) {
    ret.port = parseInt(val, 10);
    val = '';
  }

  if (!ret.name) {
    ret.name = ret.server;
  }

  return ret;
}
/**
 * Scan though an object and extend any dot notated keys
 * @param {Object} confObj Source object to traverse
 */


function dedotObject(confObj, _place) {
  var place = _place || [];
  var regex = /\w\.\w/;

  _lodash.default.each(confObj, function (val, key) {
    var ourPlace = (0, _concat.default)(place).call(place, [key]);

    if ((0, _typeof2.default)(val) === 'object') {
      dedotObject(confObj[key], ourPlace);
      return;
    }

    if (regex.test(key)) {
      delete confObj[key];

      _lodash.default.set(confObj, ourPlace.join('.'), val);
    }
  });
}
/**
 * Replace the target object with source, while keeping the target object reference intact.
 * Delete all the properties from the target object and copy the properties from source
 * over to the target.
 * a = {one: 1, two: 2, three: 3}
 * b = {four: 4, five: 5, six: 6}
 * replaceObjectProps(a, b)
 * a.one === undefined;
 * a.six === 6;
 * @param {Object} target The target object that will be replaced
 * @param {Object} source The source object from which all properties will be copied from
 */


function replaceObjectProps(target, source) {
  (0, _keys.default)(target).forEach(function (prop) {
    return delete target[prop];
  });
  (0, _keys.default)(source).forEach(function (prop) {
    target[prop] = source[prop];
  });
}
/**
 * Create an ISO8601 formatted date
 * @param {Date} date The date object to create the time from. Defaults to the current time
 */


function dateIso(date) {
  var d = date || new Date();
  return strftimeUTC('%Y-%m-%dT%H:%M:%S.%LZ', d);
}
/**
 * Cast a string to an int, returning 0 if it fails
 * @param {String} inp The number as a string to convert to an int
 */


function parseIntZero(inp) {
  var int = parseInt(inp, 10);
  return (0, _isNan.default)(int) ? 0 : int;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["helpers"]) window._kiwi_exports["helpers"] = {};
window._kiwi_exports["helpers"]["Misc"]
window._kiwi_exports.helpers.Misc = exports.default ? exports.default : exports;
