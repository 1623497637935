"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _Avatar = _interopRequireDefault(require("./Avatar"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default,
    Avatar: _Avatar.default
  },
  props: ['network'],
  data: function data() {
    return {
      is_usermenu_open: false
    };
  },
  computed: {
    networkName: function networkName() {
      var name = TextFormatting.t('no_network');

      if (this.network) {
        name = this.network.name;
      }

      return name;
    },
    getUser: function getUser() {
      return this.network && this.network.currentUser() ? this.network.currentUser() : null;
    },
    isConnected: function isConnected() {
      return this.network && this.network.state === 'connected';
    }
  },
  methods: {
    clickForget: function clickForget() {
      var msg = 'This will delete all stored networks and start fresh. Are you sure?';
      /* eslint-disable no-restricted-globals, no-alert */

      var confirmed = confirm(msg);

      if (!confirmed) {
        return;
      }

      this.$state.persistence.forgetState();
      window.location.reload();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["StateBrowserUsermenu"]
window._kiwi_exports.components.StateBrowserUsermenu = exports.default ? exports.default : exports;
